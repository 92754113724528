<template>
	<div id="" class="input-heading">
		<div v-if="serverBusy" id="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<h2 v-if="verificationSuccess">{{ this.languageStrings.registrationAndPhoneVerificationSuccess }}!</h2>
		<div class="input-section" v-if="!smsSent">
			<h2>{{ languageStrings.register }}</h2>
			<label for="displayName">{{ languageStrings.playersDisplayName }}</label>
			<input
				type="text"
				id="displayName"
				:name="languageStrings.playersDisplayName"
				v-model="displayName"
				:placeholder="languageStrings.playersDisplayName"
				autocomplete="nickname"
			/>

			<CountryDropdownMenu
				v-if="countryList.length > 0"
				:systemSettings="systemSettings"
				:playerState="playerState"
				:countryList="countryList"
				:languageStrings="languageStrings"
				:countryDropdownType="`PhoneNumber`"
			/>

			<label for="phoneNumber">{{ languageStrings.playersPhoneNumber }}</label>
			<input
				type="text"
				id="phoneNumber"
				:name="languageStrings.playersPhoneNumber"
				@keydown.enter="registerNewUser()"
				v-model="phoneNumber"
				:placeholder="languageStrings.playersPhoneNumber"
				:maxlength="systemSettings.allowPhoneExtensionDigits ? '' : phoneNumberLength"
				autocomplete="tel"
				pattern="[0-9]+"
			/>

			<label for="password">{{ languageStrings.password }}</label>
			<div class="password-container">
				<input
					:type="unhidePassword ? 'text' : 'password'"
					id="password"
					name="password"
					v-model="newPassword"
					:placeholder="languageStrings.password"
					autocomplete="new-password"
					:minlength="serverRules.passwordRules?.minimumLength"
					:maxlength="serverRules.passwordRules?.maximumLength"
					:pattern="`.{${this.serverRules.passwordRules?.minimumLength},}`"
				/>
				<span
					@click="unhidePassword = unhidePassword ? false : true"
					:class="unhidePassword ? 'hidden' : ''"
					:title="unhidePassword ? languageStrings.hidePassword : languageStrings.showPassword"
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="eye" viewBox="0 0 16 16">
						<path
							d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
						/>
						<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
					</svg>
				</span>
			</div>

			<label for="confirmPassword">{{ languageStrings.confirmPassword }}</label>
			<div class="password-container">
				<input
					:type="unhideConfirm ? 'text' : 'password'"
					id="confirmPassword"
					name="confirmPassword"
					@keydown.enter="registerNewUser()"
					v-model="confirmPassword"
					:placeholder="languageStrings.confirmPassword"
					autocomplete="off"
					:minlength="serverRules.passwordRules?.minimumLength"
					:maxlength="serverRules.passwordRules?.maximumLength"
					:pattern="`.{${serverRules.passwordRules?.minimumLength},}`"
				/>
				<span
					@click="unhideConfirm = unhideConfirm ? false : true"
					:class="unhideConfirm ? 'hidden' : ''"
					:title="unhideConfirm ? languageStrings.hidePassword : languageStrings.showPassword"
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="eye" viewBox="0 0 16 16">
						<path
							d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
						/>
						<path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
					</svg>
				</span>
			</div>

			<div class="checkbox">
				<input type="checkbox" id="ageCheck" name="Age Check" :title="languageStrings.ageCheckText" v-model="ageCheck" />
				<label for="ageCheck" :title="languageStrings.ageCheckText">{{ languageStrings.ageCheckText }}</label>
			</div>

			<TermsAndConditions
				v-if="!termsViewed && tosContent"
				:playerState="playerState"
				:languageStrings="languageStrings"
				:languageErrorStrings="languageErrorStrings"
				:tosContent="tosContent"
				:latestVersion="latestVersion"
			/>

			<span v-if="capsLockOn" style="color: #ff0; text-align: center; font-weight: 700">Caps lock is ON</span>
			<span v-if="!captchaResponse && captchaMessage" style="color: #ff0; text-align: center; font-weight: 700">{{ captchaMessage }}</span>
			<Captcha v-if="serverRules.captchaType !== 'None'" :serverRules="serverRules" :isMobile="isMobile" :languageStrings="languageStrings" />
			<div class="button-container">
				<button class="btn" type="button" :title="languageStrings.verifyPhoneNumber" @click="registerNewUser()">
					{{ languageStrings.verifyPhoneNumber }}
				</button>
				<router-link to="/" :title="languageStrings.back" class="btn back">{{ languageStrings.back }}</router-link>
			</div>
			<small style="text-align: center"
				>{{ languageStrings.passwordRequirements1 }} {{ serverRules?.passwordRules?.minimumLength }}
				{{ languageStrings.passwordRequirements2 }}
				<span v-if="serverRules?.passwordRules?.minimumNumberOfCharacterClasses > 0">
					{{ languageStrings.passwordRequirements3 }} {{ serverRules?.passwordRules?.minimumNumberOfCharacterClasses }}
					{{ languageStrings.passwordRequirements4 }}
				</span>
			</small>
			<div id="pw-strength-container">
				<p>
					{{ languageStrings.passwordStrength }}:
					<span v-if="newPassword?.length > 0 && strengthCheck =='weak'" class="score-words" :class="strengthCheck">{{ languageStrings.weak }}</span>
					<span v-if="newPassword?.length > 0 && strengthCheck =='medium'" class="score-words" :class="strengthCheck">{{ languageStrings.medium }}</span>
					<span v-if="newPassword?.length > 0 && strengthCheck =='strong'" class="score-words" :class="strengthCheck">{{ languageStrings.strong }}</span>
				</p>
				<progress id="pw-strength" class="custom-bar" :class="strengthCheck" :value="pwStrength" max="100">Password strength</progress>
			</div>
			<small style="text-align: center">{{ languageStrings.smsDisclaimer }}</small>
		</div>

		<div v-if="smsSent && !verificationSuccess" class="input-section">
			<h3>{{ languageStrings.pleaseEnterSmsVerificationCode }}</h3>
			<!-- <label for="displayName">Player Display Name</label> -->
			<input
				type="number"
				name="verificationCode"
				v-model="verificationCode"
				@keydown.enter="verifyPhoneNumber()"
				placeholder="Verification Code"
				autocomplete="off"
			/>
			<button class="btn" type="button" :title="languageStrings.verifyPhoneNumber" @click="verifyPhoneNumber()">{{ languageStrings.verifyPhoneNumber }}</button>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import router from "@/router";
import { onBeforeUnmount } from "vue";
import sharedScripts from "@/dependencies/sharedScripts";
import Captcha from "@/components/Captcha";
import CountryDropdownMenu from "@/components/CountryDropdownMenu";
import TermsAndConditions from "@/components/TermsAndConditions";

// Tools from awesome-phonenumber. For now were just using parsePhoneNumber
// https://www.npmjs.com/package/awesome-phonenumber
import {
	parsePhoneNumber,
	// getNumberFrom,
	// getExample,
	// getCountryCodeForRegionCode,
	// getRegionCodeForCountryCode,
	// getSupportedCallingCodes,
	// getSupportedRegionCodes,
	// getAsYouType,
} from "awesome-phonenumber";

export default {
	name: "RegisterNewUser",
	components: {
		Captcha,
		CountryDropdownMenu,
		TermsAndConditions,
	},
	props: {
		playerState: Object,
		capsLockOn: Boolean,
		serverRules: Object,
		isMobile: Boolean,
		systemSettings: Object,
		countryList: Array,
		languageStrings: Object,
		languageErrorStrings: Object,
	},
	data() {
		return {
			status: Object.assign({}, this.globalStatus),
			unhidePassword: false,
			unhideConfirm: false,
			smsSent: false,
			displayName: "",
			phoneNumber: "",
			newPassword: "",
			confirmPassword: "",
			verificationCode: 0,
			verificationSuccess: false,
			matchedCharClasses: {},
			validNumberCharClasses: false,
			pwStrength: 0,
			strengthCheck: "weak",
			captchaResponse: "",
			captchaMessage: "",
			selectedCountry: {},
			phoneLengthLimitation: this.systemSettings.allowPhoneExtensionDigits ? "" : this.phoneNumberLength,
			isPossiblePhone: false,
			possiblePhoneNumber: "",
			shortenedNumber: "",
			smsTimeout: false,
			ageCheck: false,
			termsViewed: false,
			latestVersion: {},
			latestDocumentAcknowlegements: [],
			tosContent: "",
			serverBusy: false,
			busyText: "",
		};
	},
	watch: {
		newPassword() {
			this.passwordStrengthCheck();
		},
		confirmPassword() {
			this.passwordStrengthCheck();
		},
	},
	async created() {
		this.getLatestTosDocument();
		this.eventBus.on("countrySelected", (payload) => {
			this.selectedCountry = payload;
		});
		this.eventBus.on("captchaSuccess", (payload) => {
			this.captchaResponse = payload;
		});
		this.eventBus.on("termsViewed", () => {
			this.termsViewed = true;
			this.eventBus.emit("initializeCaptcha");
		});
		onBeforeUnmount(() => {
			this.eventBus.off("countrySelected");
			this.eventBus.off("captchaSuccess");
			this.eventBus.off("termsViewed");
		});
	},
	methods: {
		async getLatestTosDocument() {
			this.serverBusy = true;
			this.busyText = this.languageStrings.loadingTermsAndConditions;

			try {
				this.tosList = await this.listDocuments(this, "tos", 10);
				if (!this.tosList?.length > 0) {
					this.serverBusy = false;
					this.busyText = "";
					return;
				}
				this.latestVersion = this.compareDocumentVersions(this.tosList);

				let documentUrl = this.latestVersion.url.indexOf("http") === 0 ? this.latestVersion.url : `./${this.latestVersion.url}`;

				let content = await fetch(documentUrl);
				let dataJson = await content.text();
				this.tosContent = this.removeScriptTag(dataJson);

				this.serverBusy = false;
				this.busyText = "";
			} catch (e) {
				console.error(e);
				this.serverBusy = false;
				this.busyText = "";
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
			}
		},
		passwordStrengthCheck() {
			this.pwStrength = this.scorePassword(this.newPassword);

			if (this.pwStrength <= 33) this.strengthCheck = "weak";
			if (this.pwStrength > 33 && this.pwStrength < 66) this.strengthCheck = "medium";
			if (this.pwStrength >= 66) this.strengthCheck = "strong";

			if (this.newPassword === this.confirmPassword && this.newPassword.length >= this.serverRules.passwordRules?.minimumLength) {
				this.matchedCharClasses = this.characterClassCheck(this.newPassword, this.serverRules.passwordRules?.minimumNumberOfCharacterClasses);
				this.validNumberCharClasses = this.matchedCharClasses.characterClassesValid;
			}
		},
		validatePhoneExtensionDigits() {
			// This is mostly for testing.
			// QA often has accounts set up with too many digits to validate.
			// We truncate to a possibly valid number before validating.
			// If systemSettings.allowPhoneExtensionDigits === true then we will send the unaltered number to the server.

			// type phoneNumberPossibility =
			// | 'is-possible'
			// | 'invalid-country-code'
			// | 'too-long'
			// | 'too-short'
			// | 'unknown'

			this.possiblePhoneNumber = `+${this.selectedCountry.countryPrefix}${this.phoneNumber}`;
			let parsedPhoneNumber = parsePhoneNumber(this.possiblePhoneNumber);
			let phoneNumberPossibility = parsedPhoneNumber.possibility;

			if (phoneNumberPossibility !== "too-long" && phoneNumberPossibility !== "unknown" && phoneNumberPossibility !== "is-possible") {
				this.isPossiblePhone = false;
				return;
			}

			if (phoneNumberPossibility === "too-long" && !this.systemSettings.allowPhoneExtensionDigits) {
				this.isPossiblePhone = false;
				return;
			}

			for (let i = 0; i < this.possiblePhoneNumber.length && phoneNumberPossibility !== "is-possible"; i++) {
				parsedPhoneNumber = parsePhoneNumber(this.possiblePhoneNumber);
				phoneNumberPossibility = parsedPhoneNumber.possibility;
				if (phoneNumberPossibility !== "is-possible")
					this.possiblePhoneNumber = this.possiblePhoneNumber.substring(0, this.possiblePhoneNumber.length - 1);
			}

			this.shortenedNumber = this.possiblePhoneNumber;

			this.possiblePhoneNumber = this.isPossiblePhone = phoneNumberPossibility === "is-possible" ? true : false;
		},
		async registerNewUser() {
			let hasErrors = false;

			// super long upper limit to phone nuymber length when systemSettings.allowPhoneExtensionDigits == true.
			// No one would ever need a number that large in the real world but this is for Dev testing.
			let phoneNumberLimit = this.systemSettings.allowPhoneExtensionDigits ? 200 : this.phoneNumberLength;

			this.validatePhoneExtensionDigits();

			if (!this.selectedCountry.countryPrefix) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.selectCountry;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if ((!this.phoneNumber || !this.isPossiblePhone) && !this.systemSettings.allowPhoneExtensionDigits) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.phoneInvalid;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (!this.displayName) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.enterDisplayName;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (!this.newPassword) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.providePassword;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.newPassword !== this.confirmPassword) {
				this.status.ok = false;
				this.status.message = this.languageErrorStrings.passwordDoNotMatch;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.newPassword?.length < this.serverRules.passwordRules?.minimumLength) {
				this.status.ok = false;
				this.status.message = `${this.languageErrorStrings.passwordMustHaveAtLeast} ${this.serverRules.passwordRules?.minimumLength} ${this.languageErrorStrings.characters}`;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.newPassword?.length > 0 && !this.validNumberCharClasses) {
				this.status.ok = false;
				this.status.message = `${this.languageErrorStrings.passwordMustHaveAtLeast} ${this.serverRules.passwordRules?.minimumNumberOfCharacterClasses} ${this.languageErrorStrings.characterClassesError}`;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (!this.ageCheck) {
				this.status.ok = false;
				this.status.message = `${this.languageErrorStrings.ageCheckFalse}`;
				this.eventBus.emit("updateStatus", this.status);
				hasErrors = true;
			}

			if (this.serverRules.captchaType !== "None" && !this.captchaResponse) {
				this.captchaMessage = this.languageErrorStrings.challengeNotSolved;
				hasErrors = true;
			}

			if (hasErrors === true) return;

			this.captchaMessage = "";

			let body = {
				PhoneNumber: this.phoneNumber.toString(),
				PhoneCountryCode: this.playerState?.playersCountry?.countryPrefix,
				DisplayName: this.displayName,
				password: this.newPassword,
				RequestVerificationCode: true,
				CaptchaResponse: this.captchaResponse,
			};

			// let body2 = {
			// 	phoneNumber: this.phoneNumber.toString(),
			// 	phoneCountryCode: this.playerState?.playersCountry?.countryPrefix,
			// 	displayName: this.displayName,
			// 	password: this.newPassword,
			// 	requestVerificationCode: true,
			// 	captchaResponse: this.captchaResponse,
			// };

			let requestUrl = new URL("/api/v1/user/register", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok) this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (this.status.ok) {
					this.smsSent = true;
					let newPlayer = {
						phoneNumber: this.phoneNumber.toString(),
						displayName: this.displayName,
					};
					this.eventBus.emit("updatePlayerState", newPlayer);
					this.status.ok = true;
					this.status.message = this.languageErrorStrings.registrationSuccess;
				}

				this.eventBus.emit("updateStatus", this.status);
				return;
			} catch (e) {
				console.error(e);
				this.status.ok = false;
				this.status.message = e;
				this.eventBus.emit("updateStatus", this.status);
			}
		},
		async verifyPhoneNumber() {
			let body = {
				PhoneNumber: this.phoneNumber.toString(),
				Code: this.verificationCode,
			};

			let requestUrl = new URL("/api/v1/user/verification", this.rabbitsfootHostUrl);
			let headerObj = new Headers();
			headerObj.append("Authorization", `Bearer ${this.playerState.accessToken}`);
			headerObj.append("Content-Type", "application/json; charset=utf-8");
			let request = new Request(requestUrl.toString(), {
				method: "POST",
				body: JSON.stringify(body),
				headers: headerObj,
			});

			try {
				const response = await fetch(request);

				let fetchStatus = sharedScripts.checkFetchErrors(response, this.languageErrorStrings);

				if (fetchStatus && !fetchStatus.ok) {
					this.eventBus.emit("updateStatus", fetchStatus);
					if (fetchStatus.forceLogout === true) this.eventBus.emit("forceLogout");
					return;
				}

				let dataJson = await response.json();

				if (fetchStatus.ok) this.status = sharedScripts.checkSuccessErrors(dataJson.status, this.languageErrorStrings);

				if (dataJson?.status === "Success") {
					this.verificationSuccess = true;
					router.push("/");
				}

				this.eventBus.emit("updateStatus", this.status);
			} catch (e) {
				console.error(e);
			}
		},
	},
};
</script>

<style scoped>
h3 {
	text-align: center;
}

.input-section {
	display: flex;
	flex-direction: column;
	width: 85%;
	margin: auto;
	background-color: #24272c;
	padding: 15px;
	border-radius: 8px;
	box-shadow: 2px 3px 8px rgb(0 0 0 / 75%), inset 4px 3px 10px 4px rgb(42 63 88 / 30%), inset -2px -2px 10px 2px rgb(0 0 0 / 30%);
}

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.input-section {
		margin-bottom: 100px;
	}
}

.button-container {
	display: flex;
}

.back {
	/* background: #efefef; */
	/* padding: 5px 10px; */
	/* margin: auto auto auto 0; */
	text-decoration: none;
	/* position: absolute; */
}

.checkbox * {
	margin-bottom: 15px;
	cursor: pointer;
}

#loading {
	display: grid;
	align-content: center;
	justify-content: center;
	background-color: #000;
	height: 100%;
	backdrop-filter: blur(6px);
}

.loading-message {
	margin-top: 15px;
}

@media (min-width: 768px) {
	.input-section {
		padding: 30px;
		width: 50%;
	}
}
</style>
